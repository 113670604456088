<template>
    <div class="form-group">
        <label :for="fieldId">{{ title }}</label>
        <b-form-datepicker :id="fieldId" v-model="value" @input="onInput" reset-button :min="min" class="form-control" :class="{ 'is-invalid': valueErrors.length }"></b-form-datepicker>
        <div class="invalid-feedback" v-for="(text, i) in valueErrors" :key="i">{{ text }}</div>
    </div>
</template>
<script>
export default {
    props:['value', 'type', 'name', 'title', 'errors', 'disabled'],
    watch: {
        errors(obj) {
            this.valueErrors = obj.hasOwnProperty(this.name) ? obj[this.name] : [];
        }
    },
    data() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const minDate = new Date(today)

      return {
          fieldId: null,
          min: minDate,
          valueErrors: []
      }
    },
    methods: {
        onInput(date) {
            this.value = date;
            this.$emit('input', date);
        }
    },
    mounted() {
        this.fieldId = Math.random().toString(36).replace(/[^a-z]+/g, '');
    }
}
</script>