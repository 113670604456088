<template>
   <nav class="navbar navbar-expand navbar-dark bg-dark">	    
        <div class="collapse navbar-collapse container" id="navbarSupportedContent">	   
            <ul class="navbar-nav mr-auto">	        
                <li class="nav-item"><router-link class="nav-link pr-5" style="color: #fff;font-weight: 700;" to="/admin">D&D Dashboard</router-link></li>
                <li v-if="$auth.check(admin.id) || $auth.check(editor.id)">
                    <router-link :to="{ path: '/admin/customers' }" class="nav-link" href="#" role="button">Customers</router-link>
                </li>	
                <li v-if="$auth.check()" class="nav-item">
                    <router-link  :to="{ name: 'Orders' }" class="nav-link" href="#" role="button">Orders</router-link>	
                </li>
            </ul>
            <template v-if="$auth.check()">	
                <button class="nav-item float-right btn btn-primary" @click.prevent="$auth.logout()">Logout</button>	
            </template>	
        </div>	  
    </nav>	    
</template>
<script>
import { userType } from './../mixins/user-type.mixin';

    export default {
        mixins: [userType],
        data() {
            return {
                routes: {
                    unlogged: [
                        { path: '/login', name: 'Login' }
                    ],
                    user: [{ path: '/dashboard', name: 'Dashboard' }],
                    admin: [{ path: '/admin/users', name: 'Users' }],
                    adminContent: [
                        { path: '/admin/photos', name: 'Photos' },
                        { path: '/admin/videos', name: 'Videos' },
                        { path: '/admin/emails', name: 'Contacts'},
                        { path: '/admin/packages', name: 'Packages' },
                        { path: '/admin/faq', name: 'FAQ'}
                    ],
                    adminSettings: [
                        { path:'/admin/settings', name: 'Settings' },
                        { path: '/admin/users', name: 'Users' }
                    ],
                }
            }
        }
    }
</script>
<style scoped>
.dropdown-menu {
    left: auto;
}
</style>