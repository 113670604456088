<template>
    <div class="form-group">
        <label :for="fieldId">{{ title }}</label>
        <input :type="type ? type : 'text'" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" class="form-control" :class="{ 'is-invalid': valueErrors.length }" :id="fieldId" :disabled="disabled">
        <div class="invalid-feedback" v-for="(text, i) in valueErrors" :key="i">{{ text }}</div>
    </div>
</template>
<script>
export default {
    props:['value', 'type', 'name', 'title', 'errors', 'disabled'],
    watch: {
        errors(obj) {
            this.valueErrors = obj.hasOwnProperty(this.name) ? obj[this.name] : [];
        }
    },
    data() {
        return {
            fieldId:null,
            valueErrors: []
        }
    },
    mounted() {
        this.fieldId = Math.random().toString(36).replace(/[^a-z]+/g, '');
    }
}
</script>