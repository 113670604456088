export const notificationMixin = {
    methods: {
        successNtf(title, message) {
            this.$bvToast.toast(message, {
                title: title,
                toaster: 'b-toaster-bottom-right',
                variant: 'success',
                solid: true
            });
        },
        errorNtf(title, message) {
            this.$bvToast.toast(message, {
                title: title,
                toaster: 'b-toaster-bottom-right',
                variant: 'danger',
                solid: true
            });
        }
    }
};